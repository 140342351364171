import useActionPermissions from "@hooks/useActionPermissions";
import { ALL_FALSE_CONTENT_PERMISSIONS } from "@src/components/libraryItemDetailPages/hooks/useContentPermissions";
import {
  ContentPermissionsFragment,
  useContentPermissionsQuery,
} from "@src/components/libraryItemDetailPages/hooks/useContentPermissions.generated";
import { draftVersionSelectedAtom, pathVersionPathIdAtom } from "../atoms";
import { useAtomValue } from "jotai";
import { useMemo } from "react";
import { isEqual } from "lodash";
import { selectAtom } from "jotai/utils";

const usePathBuilderContentPermission = ():
  | ContentPermissionsFragment
  | undefined => {
  // const pathId = 564332;
  const { userActionPermissions } = useActionPermissions();
  const draftVersionSelected = useAtomValue(draftVersionSelectedAtom);
  const input = useAtomValue(
    useMemo(
      () =>
        selectAtom(
          pathVersionPathIdAtom,
          (pathId) => ({
            pathId,
          }),
          isEqual,
        ),
      [],
    ),
  );
  const { data } = useContentPermissionsQuery({
    variables: input,
    skip: !input.pathId,
    fetchPolicy: "cache-first",
  });
  const permissions = data?.ContentPermissions;
  const hasEditContentPermission =
    (permissions?.hasEditContentPermission && draftVersionSelected) || false;
  const result = useMemo(
    () => ({
      hasEditAdminAndManagerSharingPermission:
        permissions?.hasEditAdminAndManagerSharingPermission || false,
      hasShareWithIndividualsPermission:
        permissions?.hasShareWithIndividualsPermission || false,
      hasEditContentPermission,
      hasPublishPermission:
        (permissions?.hasPublishPermission && draftVersionSelected) || false,
      hasCommentPermission:
        (permissions?.hasCommentPermission && draftVersionSelected) ||
        hasEditContentPermission ||
        false,
    }),
    [draftVersionSelected, hasEditContentPermission, permissions],
  );

  if (!data?.ContentPermissions) return undefined;
  if (!userActionPermissions?.createContent) {
    return ALL_FALSE_CONTENT_PERMISSIONS;
  }
  return result;
};

export default usePathBuilderContentPermission;
