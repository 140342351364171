import { useEffect } from "react";
import { isLessonIncompleteAI } from "@utils/courses";
import { CourseContentCourseVersionFragment } from "../build/CourseBuildContent.generated";

const useMaybePollCourseVersion = (
  startPolling: (pollInterval: number) => void,
  stopPolling: () => void,
  courseVersion?: CourseContentCourseVersionFragment | null,
): void => {
  useEffect(() => {
    if (
      hasIncompleteAIGeneration(courseVersion) ||
      hasPendingScormGeneration(courseVersion)
    ) {
      startPolling(1500);
    } else {
      stopPolling();
    }
  }, [startPolling, stopPolling, courseVersion]);
};

export default useMaybePollCourseVersion;

export const hasIncompleteAIGeneration = (
  courseVersion?: CourseContentCourseVersionFragment | null,
): boolean => {
  return !!courseVersion?.lessons?.some((lesson) =>
    isLessonIncompleteAI(lesson),
  );
};

export const hasPendingScormGeneration = (
  courseVersion?: CourseContentCourseVersionFragment | null,
): boolean => {
  return !!(
    courseVersion?.course.scormUpload &&
    !courseVersion?.course.scormUpload.completedAt
  );
};
