import { CourseOutlineFragment } from "@src/components/contentDetail/premiumPathDetail/PremiumPathDetailContainer.generated";
import {
  PathBuilderContentMembershipFragment,
  PathBuilderCourseFragment,
  PathBuilderFileFragment,
  PathBuilderPathVersionFragment,
  PathBuilderSkillFragment,
  SectionBreakFragment,
} from "../ModuleDetailPageContainer.generated";

export const getMembershipScreenId = (membershipUuid: string): string =>
  membershipUuid;

export class PathMembershipSectionItems {
  readonly id: string;
  readonly items: PathMembershipItemData[];
  constructor(id: string, items: PathMembershipItemData[]) {
    this.id = id;
    this.items = items;
  }
}

export class PathMembershipItemData {
  readonly id: string;
  readonly fractionPosition: number;
  readonly pathVersion: PathBuilderPathVersionFragment;
  readonly membership: PathBuilderContentMembershipFragment;
  readonly validationErrorCount: number;
  constructor(
    id: string,
    fractionPosition: number,
    pathVersion: PathBuilderPathVersionFragment,
    membership: PathBuilderContentMembershipFragment,
    validationErrorCount: number,
  ) {
    this.id = id;
    this.fractionPosition = fractionPosition;
    this.pathVersion = pathVersion;
    this.membership = membership;
    this.validationErrorCount = validationErrorCount;
  }
}

export class SectionBreakMembershipItemData extends PathMembershipItemData {
  readonly sectionBreak: SectionBreakFragment;
  constructor(
    id: string,
    fractionPosition: number,
    pathVersion: PathBuilderPathVersionFragment,
    membership: PathBuilderContentMembershipFragment,
    validationErrorCount: number,
    sectionBreak: SectionBreakFragment,
  ) {
    super(id, fractionPosition, pathVersion, membership, validationErrorCount);
    this.sectionBreak = sectionBreak;
  }
}

export class ContentMembershipItemData extends PathMembershipItemData {
  constructor(
    id: string,
    fractionPosition: number,
    pathVersion: PathBuilderPathVersionFragment,
    membership: PathBuilderContentMembershipFragment,
    validationErrorCount: number,
  ) {
    super(id, fractionPosition, pathVersion, membership, validationErrorCount);
  }
}

export class CourseContentMembershipItemData extends ContentMembershipItemData {
  course: PathBuilderCourseFragment;
  validationErrorCount: number;
  constructor(
    id: string,
    fractionPosition: number,
    pathVersion: PathBuilderPathVersionFragment,
    membership: PathBuilderContentMembershipFragment,
    validationErrorCount: number,
    course: PathBuilderCourseFragment,
  ) {
    super(id, fractionPosition, pathVersion, membership, validationErrorCount);
    this.course = course;
    this.validationErrorCount = validationErrorCount;
  }
}

export class FileContentMembershipItemData extends ContentMembershipItemData {
  file: PathBuilderFileFragment;
  constructor(
    id: string,
    fractionPosition: number,
    pathVersion: PathBuilderPathVersionFragment,
    membership: PathBuilderContentMembershipFragment,
    validationErrorCount: number,
    file: PathBuilderFileFragment,
  ) {
    super(id, fractionPosition, pathVersion, membership, validationErrorCount);
    this.file = file;
  }
}

export class SkillContentMembershipItemData extends ContentMembershipItemData {
  skill: PathBuilderSkillFragment;
  constructor(
    id: string,
    fractionPosition: number,
    pathVersion: PathBuilderPathVersionFragment,
    membership: PathBuilderContentMembershipFragment,
    validationErrorCount: number,
    skill: PathBuilderSkillFragment,
  ) {
    super(id, fractionPosition, pathVersion, membership, validationErrorCount);
    this.skill = skill;
  }
}

export class courseOutlineContentMembershipItemData extends ContentMembershipItemData {
  courseOutline: CourseOutlineFragment;
  constructor(
    id: string,
    fractionPosition: number,
    pathVersion: PathBuilderPathVersionFragment,
    membership: PathBuilderContentMembershipFragment,
    validationErrorCount: number,
    courseOutline: CourseOutlineFragment,
  ) {
    super(id, fractionPosition, pathVersion, membership, validationErrorCount);
    this.courseOutline = courseOutline;
  }
}
