import Spacer from "@components/ui/Spacer";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import useDropdown from "@hooks/useDropdown";
import { shadows } from "@src/deprecatedDesignSystem/styles/shadows";
import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import TextStyles from "@src/deprecatedDesignSystem/styles/TextStyles";
import { css, StyleDeclaration, StyleSheet } from "aphrodite";
import { CSSProperties, FC, useMemo } from "react";
import RelativePortal from "react-relative-portal";

export type Choice = {
  value: string;
  label: string;
};

export type InputStyle = "inline" | "button";

type Props = {
  inputStyle: InputStyle;
  value: string | undefined;
  onChange: (choice: Choice) => void;
  choices: Choice[];
  style?: CSSProperties;
  labelStyle?: CSSProperties;
  disabled?: boolean;
  buttonMenuStyleDeclaration?: StyleDeclaration;
};

const SelectInput: FC<Props> = ({
  inputStyle,
  value,
  onChange,
  choices,
  style,
  labelStyle,
  disabled,
  buttonMenuStyleDeclaration,
}) => {
  const selectedOption = useMemo(() => {
    return choices.find((c) => c.value === value);
  }, [choices, value]);
  const { dropdownMenuRef, isDropdownVisible, setIsDropdownVisible } =
    useDropdown();
  return (
    <>
      {inputStyle === "inline" && (
        <div
          style={{
            position: "relative",
            marginLeft: 8,
            pointerEvents: disabled ? "none" : undefined,
            ...style,
          }}
        >
          <div
            className={css(styles.selectedOption, disabled && styles.disabled)}
            onClick={() => setIsDropdownVisible(true)}
          >
            <div style={{ textAlign: "right", ...labelStyle }}>
              {selectedOption?.label}
            </div>
            {!disabled && (
              <DeprecatedIcon
                type={isDropdownVisible ? "chevron-up" : "chevron-down"}
                color={deprecatedTones.gray8}
                style={{ marginLeft: 2 }}
              />
            )}
          </div>
          <div className={css(styles.relativePortalContainer)}>
            {isDropdownVisible && (
              // @ts-ignore-next-line
              <RelativePortal component="div">
                <div className={css(styles.menu)} ref={dropdownMenuRef}>
                  {choices.map((c) => (
                    <div
                      key={c.value}
                      className={css(
                        styles.option,
                        c.value === selectedOption?.value && styles.selected,
                      )}
                      onClick={() => {
                        onChange(c);
                        setIsDropdownVisible(false);
                      }}
                    >
                      {c.value === selectedOption?.value ? (
                        <DeprecatedIcon
                          type="checkmark"
                          style={{ marginRight: 16 }}
                        />
                      ) : (
                        <Spacer size={32} axis="horizontal" />
                      )}
                      <span style={{ flex: 1 }}>{c.label}</span>
                    </div>
                  ))}
                </div>
              </RelativePortal>
            )}
          </div>
        </div>
      )}
      {inputStyle === "button" && (
        <div className={css(styles.buttonMenuContainer)}>
          <div
            className={css(TextStyles.textInput, styles.button)}
            onClick={() => setIsDropdownVisible(true)}
          >
            {selectedOption?.label}
            {!disabled && (
              <DeprecatedIcon
                type={isDropdownVisible ? "chevron-up" : "chevron-down"}
                color={deprecatedTones.gray8}
                style={{ marginLeft: 2 }}
              />
            )}
          </div>
          {isDropdownVisible && (
            <div
              className={css(styles.buttonMenu, buttonMenuStyleDeclaration)}
              ref={dropdownMenuRef}
            >
              {choices.map((choice) => (
                <div
                  key={choice.value}
                  className={css(styles.buttonMenuChoice)}
                  onClick={() => {
                    onChange(choice);
                    setIsDropdownVisible(false);
                  }}
                >
                  {choice.label}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  buttonMenuContainer: {
    position: "relative",
    cursor: "pointer",
  },
  buttonMenuChoice: {
    padding: 12,
    fontWeight: 500,
    fontSize: 14,
    borderRadius: 4,
    cursor: "pointer",
    ":hover": { backgroundColor: deprecatedTones.gray4Alpha },
  },
  selectedOption: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    color: deprecatedColors.onBackground,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "24px",
  },
  menu: {
    position: "absolute",
    zIndex: 1000,
    boxShadow: shadows.dropdownShadow,
    backgroundColor: deprecatedColors.background,
    left: -4,
    padding: 8,
    borderRadius: 12,
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
  },
  buttonMenu: {
    boxShadow: shadows.dropdownShadow,
    backgroundColor: deprecatedColors.background,
    position: "absolute",
    top: 44,
    right: 0,
    width: "80%",
    padding: 8,
    borderRadius: 12,
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
    zIndex: 9999,
  },
  disabled: {
    opacity: 0.5,
  },
  option: {
    ":hover": {
      borderRadius: 12,
      backgroundColor: deprecatedTones.blue2,
    },
    padding: 8,
    borderRadius: 12,
    cursor: "pointer",
    display: "flex",
    marginBottom: 4,
    paddingRight: 60,
    whiteSpace: "nowrap",
    alignItems: "center",
    color: deprecatedColors.onBackground,
    fontSize: 14,
    fontWeight: 500,
  },
  selected: {
    backgroundColor: deprecatedTones.blue2,
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  relativePortalContainer: {
    position: "relative",
  },
});

export default SelectInput;
