import { gql } from "@apollo/client";
import usePlan from "./usePlan";
import { useModuleCountQuery } from "./useCanPublishMoreModules.generated";
import { useMemo } from "react";
import { PlanType } from "@src/types.generated";

const MAX_MODULES_ON_STARTER_PLAN = 3;

export const useCanPublishMoreModules = (pathId: number): boolean => {
  const plan = usePlan();
  const { data } = useModuleCountQuery();

  const moduleCount = useMemo(() => {
    return (
      data?.AdminLibrary.objects.filter(
        (libraryItem) =>
          !libraryItem.path?.libraryItem.name.en.includes("Opus 101") &&
          libraryItem?.path?.id !== pathId,
      ).length || 0
    );
  }, [data?.AdminLibrary.objects, pathId]);

  const cantPublish =
    plan?.planType === PlanType.Starter &&
    moduleCount >= MAX_MODULES_ON_STARTER_PLAN;
  return !cantPublish;
};

export default useCanPublishMoreModules;

gql`
  query ModuleCount {
    AdminLibrary(
      input: {
        filters: { itemTypes: { value: [PATH] }, isPublished: { value: true } }
      }
    ) {
      objects {
        id
        path {
          id
          libraryItem {
            id
            name {
              uuid
              en
            }
          }
        }
      }
    }
  }
`;
