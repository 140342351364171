import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ModuleCountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ModuleCountQuery = { __typename?: 'Query', AdminLibrary: { __typename?: 'LibraryItems', objects: Array<{ __typename?: 'LibraryItem', id: string, path?: { __typename?: 'Path', id: number, libraryItem: { __typename?: 'LibraryItem', id: string, name: { __typename?: 'TranslationSet', uuid: string, en: string } } } | null }> } };


export const ModuleCountDocument = gql`
    query ModuleCount {
  AdminLibrary(
    input: {filters: {itemTypes: {value: [PATH]}, isPublished: {value: true}}}
  ) {
    objects {
      id
      path {
        id
        libraryItem {
          id
          name {
            uuid
            en
          }
        }
      }
    }
  }
}
    `;

/**
 * __useModuleCountQuery__
 *
 * To run a query within a React component, call `useModuleCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useModuleCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModuleCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useModuleCountQuery(baseOptions?: Apollo.QueryHookOptions<ModuleCountQuery, ModuleCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModuleCountQuery, ModuleCountQueryVariables>(ModuleCountDocument, options);
      }
export function useModuleCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModuleCountQuery, ModuleCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModuleCountQuery, ModuleCountQueryVariables>(ModuleCountDocument, options);
        }
export type ModuleCountQueryHookResult = ReturnType<typeof useModuleCountQuery>;
export type ModuleCountLazyQueryHookResult = ReturnType<typeof useModuleCountLazyQuery>;
export type ModuleCountQueryResult = Apollo.QueryResult<ModuleCountQuery, ModuleCountQueryVariables>;