export enum PromptErrorType {
  MISSING_TEXT = "MISSING_TEXT",
  MISSING_GRADED_FREE_RESPONSE_ANSWER = "MISSING_GRADED_FREE_RESPONSE_ANSWER",
  MISSING_RESPONSE_OPTION_IMAGE = "MISSING_RESPONSE_OPTION_IMAGE",
  MISSING_RESPONSE_OPTION = "MISSING_RESPONSE_OPTION",
  MISSING_CORRECT_ANSWER = "MISSING_CORRECT_ANSWER",
}

export type PromptErrorsArray = Array<PromptErrorType>;

export type PromptErrorsMap = Map<string, PromptErrorsArray>;

export type CourseValidationErrors = {
  hasPendingScormGeneration: boolean;
  hasPendingAiGeneration: boolean;
  promptErrors: PromptErrorsMap;
  emptyLessons: Set<string>;
};

export const emptyCourseValidationErrors = (): CourseValidationErrors => {
  return {
    hasPendingScormGeneration: false,
    hasPendingAiGeneration: false,
    promptErrors: new Map(),
    emptyLessons: new Set(),
  };
};
