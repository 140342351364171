import { useUpdateCourseMutation } from "@components/contentDetail/courseDetail/overview/SettingsFields.generated";
import InlineEditButton from "@src/deprecatedDesignSystem/components/InlineEditButton";
import InlineEditCalendarField, {
  formatCalendarValueForDisplay,
} from "@src/deprecatedDesignSystem/components/InlineEditCalendarField";
import InlineEditField from "@src/deprecatedDesignSystem/components/InlineEditField";
import { CourseDetailOverviewFragment } from "@src/components/libraryItemDetailPages/course/CourseDetailPageHeader.generated";
import { SETTINGS_LABEL_WIDTH } from "@src/deprecatedDesignSystem/components/BuilderSettingsTable";
import { DueDateInput, DueDateInputValue } from "@src/types.generated";
import { FC, useMemo, useState } from "react";

type Props = {
  labelWidth?: number;
  course: CourseDetailOverviewFragment;
  courseLoading: boolean;
  editable: boolean;
};

const EditCourseDueDate: FC<Props> = ({
  labelWidth,
  course,
  courseLoading,
  editable,
}) => {
  const [saveError, setSaveError] = useState<string | undefined>(undefined);

  const onSaveClick = (newDueDate: string | number | undefined) => {
    const dueDateValue = getDueDateInputValue(newDueDate);

    updateCourseMutation({
      variables: {
        courseId: course.id,
        input: {
          dueDate: dueDateValue,
        },
      },
    });
  };

  const [updateCourseMutation, { loading: saveLoading }] =
    useUpdateCourseMutation({
      onCompleted: (data) => {
        if (data.updateCourse.success) {
          setSaveError(undefined);
        } else {
          setSaveError("An unexpected error occurred");
        }
      },
      onError() {
        setSaveError("An unexpected error occurred");
      },
    });

  const courseDueDate = useMemo(() => {
    if (course.libraryItem.dueDate) {
      return course.libraryItem.dueDate;
    } else if (course.libraryItem.dueDateDays) {
      return course.libraryItem.dueDateDays;
    } else {
      return undefined;
    }
  }, [course.libraryItem.dueDate, course.libraryItem.dueDateDays]);

  return (
    <InlineEditField
      icon="clock"
      label="Due date"
      containerStyle={{ alignSelf: "stretch" }}
      labelStyle={{ minWidth: labelWidth || SETTINGS_LABEL_WIDTH }}
      valueStyle={{ minWidth: SETTINGS_LABEL_WIDTH }}
    >
      {editable ? (
        <InlineEditCalendarField
          value={courseDueDate}
          label="Select a due date"
          onSave={onSaveClick}
          saveError={saveError}
          saveLoading={saveLoading}
          valueLoading={courseLoading}
        />
      ) : (
        <InlineEditButton
          values={[formatCalendarValueForDisplay(courseDueDate)]}
          loading={courseLoading}
        />
      )}
    </InlineEditField>
  );
};

export default EditCourseDueDate;

export const getDueDateInputValue = (
  newDueDate: string | number | undefined | null,
): DueDateInputValue => {
  let dueDateInput: DueDateInput;

  if (typeof newDueDate === "string") {
    dueDateInput = { dueDate: newDueDate };
  } else if (typeof newDueDate === "number") {
    dueDateInput = { dueDateDays: newDueDate };
  } else {
    dueDateInput = {};
  }
  return { value: dueDateInput };
};
