import { usePollInterval } from "@src/hooks/usePollInterval";
import { useContext, useEffect, useMemo, useState } from "react";
import gql from "graphql-tag";
import { v4 as uuidv4 } from "uuid";
import { ClientIdContext } from "@builder/shared/ClientIdContext";
import {
  BuilderActionFragment,
  useBuilderActionsQuery,
} from "@src/components/libraryItemDetailPages/hooks/usePollServerForBuilderActions.generated";
import { isNullOrUndefined } from "@utils/typeguards";
import {
  BuilderActionInput,
  GetBuilderActionsInput,
} from "@src/types.generated";
import {
  BuilderActionContentIds,
  BuilderActionContextContent,
  BuilderActionContextProviderProps,
} from "@contexts/BuilderActionContext";

type Args = {
  contentToPollActionsFor: BuilderActionContextContent[];
  applyActionFromServerFn: BuilderActionContextProviderProps["applyActionFromServer"];
};

type BuilderActionFragmentWithTypedPayload = BuilderActionFragment & {
  payload: BuilderActionInput;
};

const usePollServerForBuilderActions = (args: Args): void => {
  const [contentIdToLastAppliedActionId, setContentIdToLastAppliedActionId] =
    useState<Map<string, number | null | undefined>>(() => {
      const map = new Map<string, number | null | undefined>();
      for (const content of args.contentToPollActionsFor) {
        map.set(
          getContentId(content.contentIds),
          content.initialLastAppliedBuilderActionId,
        );
      }
      return map;
    });
  useEffect(() => {
    for (const content of args.contentToPollActionsFor) {
      const lastAppliedIdInState = contentIdToLastAppliedActionId.get(
        getContentId(content.contentIds),
      );
      if (
        isNullOrUndefined(lastAppliedIdInState) &&
        content.initialLastAppliedBuilderActionId
      ) {
        setContentIdToLastAppliedActionId((prevState) => {
          const newState = new Map(prevState);
          newState.set(
            getContentId(content.contentIds),
            content.initialLastAppliedBuilderActionId,
          );
          return newState;
        });
      }
    }
  }, [args.contentToPollActionsFor, contentIdToLastAppliedActionId]);
  const clientIdContext = useContext(ClientIdContext);
  const pollInterval = usePollInterval(1000, 1000 * 60);
  const pollInputs: GetBuilderActionsInput[] = useMemo(() => {
    return args.contentToPollActionsFor.map((x) => ({
      ...x.contentIds,
      afterBuilderActionId: contentIdToLastAppliedActionId.get(
        getContentId(x.contentIds),
      ),
      clientId: clientIdContext?.clientId || uuidv4(),
    }));
  }, [
    args.contentToPollActionsFor,
    clientIdContext?.clientId,
    contentIdToLastAppliedActionId,
  ]);
  useBuilderActionsQuery({
    pollInterval,
    skip: !clientIdContext?.clientId || pollInputs.length === 0,
    variables: {
      inputs: pollInputs,
    },
    onCompleted(data) {
      for (const action of data.BuilderActions as BuilderActionFragmentWithTypedPayload[]) {
        setContentIdToLastAppliedActionId((prevState) => {
          const newState = new Map(prevState);
          newState.set(
            getContentId({
              courseId: action.payload.courseBuilderAction?.courseId,
              pathId: action.payload.pathBuilderAction?.pathId,
            }),
            action.id,
          );
          return newState;
        });
        args.applyActionFromServerFn(
          action as BuilderActionFragmentWithTypedPayload,
        );
      }
    },
  });
};

export default usePollServerForBuilderActions;

gql`
  query BuilderActions($inputs: [GetBuilderActionsInput!]!) {
    BuilderActions(inputs: $inputs) {
      ...BuilderAction
    }
  }
  fragment BuilderAction on BuilderAction {
    id
    uuid
    clientId
    payload
    addedPathContentMembership {
      ...PathBuilderContentMembership
    }
  }
`;

const getContentId = (
  contentIds: Pick<BuilderActionContentIds, "courseId" | "pathId">,
): string => {
  return `${contentIds.courseId}-${contentIds.pathId}`;
};
