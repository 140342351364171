import { getPromptScreenId } from "@src/components/libraryItemDetailPages/course/hooks/useCourseScreensAtom";
import useTranslationModeContext from "@src/components/libraryItemDetailPages/course/hooks/useTranslationModeContext";
import {
  CourseValidationErrors,
  emptyCourseValidationErrors,
} from "@src/components/libraryItemDetailPages/course/types/validation";
import { courseValidationErrorCount } from "@src/components/libraryItemDetailPages/course/utils/publishValidation";
import { PathValidationErrors } from "@src/components/libraryItemDetailPages/module/contexts/PathBuilderContext";
import {
  ValidatePath_PathVersionFragment,
  useValidatePath_PathLazyQuery,
} from "@src/components/libraryItemDetailPages/module/hooks/useValidatePathAndOpenPublishModal.generated";
import PublishPathModal from "@src/components/libraryItemDetailPages/module/build/PublishPathModal";
import {
  pathContentValidationErrorCount,
  pathCourseOutlineValidationErrorCount,
  pathHasContentValidationErrors,
  validatePath,
} from "@src/components/libraryItemDetailPages/module/utils/publishValidation";
import {
  SkillValidationErrors,
  emptySkillValidationErrors,
  skillValidationErrorCount,
} from "@builder/skill/utils/validation";
import ConfirmationModal from "@components/modals/ConfirmationModal";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Text from "@ui/text";
import { useModal } from "@hooks/useModal";
import { useToast } from "@hooks/useToast";
import { pluralize } from "@utils/strings";
import gql from "graphql-tag";
import { useRouter } from "next/router";
import { Route } from "nextjs-routes";
import { useCallback } from "react";
import useDispatchRemoveAllCourseOutlinesInPath from "./useDispatchRemoveAllCourseOutlinesInPath";
import { useAtomCallback } from "jotai/utils";
import {
  pathVersionPathIdAtom,
  selectedMembershipUuidsAtom,
  pathValidationErrorsAtom,
} from "../atoms";
type Return = {
  validatePathAndOpenPublishModal: () => void;
  loading: boolean;
};

const useValidatePathAndOpenPublishModal = (): Return => {
  const translationModeContext = useTranslationModeContext();
  const { addToast } = useToast();
  const router = useRouter();
  const { showModal, closeModal } = useModal();
  const [pathPublishValidationQuery, { loading }] =
    useValidatePath_PathLazyQuery({
      nextFetchPolicy: "no-cache",
    });
  const removeAllCourseOutlinesInPath =
    useDispatchRemoveAllCourseOutlinesInPath();
  const validatePathAndOpenPublishModal = useAtomCallback(
    useCallback(
      async (get, set) => {
        const pathVersionPathId = get(pathVersionPathIdAtom);
        if (!pathVersionPathId) return;
        const fullPath = await pathPublishValidationQuery({
          nextFetchPolicy: "no-cache",
          variables: {
            pathId: pathVersionPathId,
          },
        });
        const pv = fullPath.data?.Path?.draftVersion;
        if (!pv) return;
        const newValidationErrors: PathValidationErrors = validatePath(pv);
        set(pathValidationErrorsAtom, newValidationErrors);
        if (pathHasContentValidationErrors(newValidationErrors)) {
          translationModeContext.setTranslationModeEnabled(false);
          const contentErrorCount =
            pathContentValidationErrorCount(newValidationErrors);
          const aiOutlineErrorCount =
            pathCourseOutlineValidationErrorCount(newValidationErrors);
          if (contentErrorCount === aiOutlineErrorCount) {
            showModal(
              <ConfirmationModal
                title={`Remove Course ${pluralize(
                  "Outline",
                  aiOutlineErrorCount,
                )}?`}
                text={
                  <AutoLayout direction="vertical">
                    <Text multiline type="P2" style={{ marginBottom: 10 }}>
                      This module has {aiOutlineErrorCount} Course{" "}
                      {pluralize("Outline", aiOutlineErrorCount)} that must be
                      turned into a course or removed to continue.
                    </Text>
                    <Text multiline type="P2" style={{ marginBottom: 10 }}>
                      Do you want to remove{" "}
                      {aiOutlineErrorCount === 1 ? "this" : "these"} now?
                    </Text>
                  </AutoLayout>
                }
                onConfirm={() => {
                  removeAllCourseOutlinesInPath();
                  closeModal();
                }}
                cancelEnabled={true}
                confirmCopy="Remove"
              />,
            );
            return;
          }
          addToast({
            iconType: "refresh",
            message: `This module has ${contentErrorCount} ${pluralize(
              "issue",
              contentErrorCount,
            )} that ${
              contentErrorCount > 1 ? "need" : "needs"
            } to be fixed. Check the red dots in the left sidebar.`,
            success: false,
          });
          const routeToFirstValidationError = getRouteToFirstValidationError(
            pv,
            newValidationErrors,
          );
          if (routeToFirstValidationError) {
            set(selectedMembershipUuidsAtom, [
              routeToFirstValidationError?.memUuid,
            ]);
            router.replace(routeToFirstValidationError?.route);
          }
        } else if (newValidationErrors.duplicateMembershipUuids.size > 0) {
          translationModeContext.setTranslationModeEnabled(false);
          const duplicateMembershipNames = pv.pathContentMemberships
            .filter((mem) =>
              newValidationErrors.duplicateMembershipUuids.has(mem.uuid),
            )
            .map(getMembershipContentName);
          addToast({
            iconType: "refresh",
            message: `This module has duplicate steps: ${duplicateMembershipNames.join(
              ", ",
            )}. Please remove the duplicates.`,
            success: false,
          });
        } else if (newValidationErrors.emptySectionBreakUuids.size > 0) {
          translationModeContext.setTranslationModeEnabled(false);
          addToast({
            iconType: "refresh",
            message: `This module has ${
              newValidationErrors.emptySectionBreakUuids.size
            } empty ${pluralize(
              "section",
              newValidationErrors.emptySectionBreakUuids.size,
            )}. Remove ${
              newValidationErrors.emptySectionBreakUuids.size === 1
                ? "it"
                : "them"
            } to continue.`,
            success: false,
          });
        } else {
          showModal(<PublishPathModal pathId={pathVersionPathId} />);
        }
      },
      [
        pathPublishValidationQuery,
        translationModeContext,
        addToast,
        showModal,
        removeAllCourseOutlinesInPath,
        closeModal,
        router,
      ],
    ),
  );
  return {
    validatePathAndOpenPublishModal,
    loading,
  };
};

export default useValidatePathAndOpenPublishModal;

const getRouteToFirstValidationError = (
  pathVersion: ValidatePath_PathVersionFragment,
  validationErrors: PathValidationErrors,
): { route: Route; memUuid: string } | null => {
  const mem = pathVersion.pathContentMemberships.find((mem) => {
    if (
      mem.course &&
      courseValidationErrorCount(
        getPathCourseValidationErrors(validationErrors, mem.course.id),
      )
    ) {
      return true;
    } else if (
      mem.skill &&
      skillValidationErrorCount(
        getPathSkillValidationErrors(validationErrors, mem.skill.id),
      )
    ) {
      return true;
    }
  });
  if (mem?.course) {
    const courseValidationErrors = getPathCourseValidationErrors(
      validationErrors,
      mem.course.id,
    );
    const promptUuid = [...courseValidationErrors.promptErrors.entries()].find(
      (x) => x[1].length > 0,
    )?.[0];
    return {
      route: {
        pathname: "/builder/path-version/[id]",
        query: {
          id: pathVersion.id,
          m: mem.uuid,
          screenId: promptUuid ? getPromptScreenId(promptUuid) : undefined,
        },
      },
      memUuid: mem.uuid,
    };
  } else if (mem?.skill) {
    return {
      route: {
        pathname: "/builder/path-version/[id]",
        query: {
          id: pathVersion.id,
          m: mem.uuid,
        },
      },
      memUuid: mem.uuid,
    };
  } else {
    return null;
  }
};

const getPathCourseValidationErrors = (
  validationErrors: PathValidationErrors,
  courseId: number,
): CourseValidationErrors => {
  return (
    validationErrors.courseIdToValidationErrors.get(courseId) ||
    emptyCourseValidationErrors()
  );
};
const getPathSkillValidationErrors = (
  validationErrors: PathValidationErrors,
  skillId: number,
): SkillValidationErrors => {
  return (
    validationErrors.skillIdToValidationErrors.get(skillId) ||
    emptySkillValidationErrors()
  );
};

export const getMembershipContentName = (
  membership: ValidatePath_PathVersionFragment["pathContentMemberships"][0],
): string => {
  const content = membership.course || membership.skill;
  if (content && content.libraryItem) {
    return content.libraryItem.name.en;
  }
  return "Unknown Content";
};

export const emptyPathValidationErrors = (): PathValidationErrors => {
  return {
    skillIdToValidationErrors: new Map(),
    courseIdToValidationErrors: new Map(),
    duplicateMembershipUuids: new Set(),
    courseOutlineIds: [],
    emptySectionBreakUuids: new Set(),
  };
};

gql`
  query ValidatePath_Path($pathId: Int!) {
    Path: AdminPath(id: $pathId) {
      id
      draftVersion {
        ...ValidatePath_PathVersion
      }
    }
  }
  fragment ValidatePath_LibraryItem on LibraryItem {
    id
    name {
      ...TranslationSet
    }
  }
  fragment ValidatePath_PathVersion on PathVersion {
    id
    pathContentMemberships {
      uuid
      sectionBreak {
        uuid
        id
      }
      course {
        id
        libraryItem {
          ...ValidatePath_LibraryItem
        }
        draftVersion {
          ...CourseContentCourseVersion
        }
      }
      courseOutline {
        ...CourseOutline
      }
      trainingResource {
        id
        libraryItem {
          ...ValidatePath_LibraryItem
        }
      }
      skill {
        id
        libraryItem {
          ...ValidatePath_LibraryItem
        }
        draftVersion {
          ...SkillBuilder_SkillVersion
        }
      }
    }
  }
`;
