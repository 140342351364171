import React, { FC, useCallback, useMemo } from "react";
import { BuilderActionInput } from "@src/types.generated";
import usePollServerForBuilderActions from "@src/components/libraryItemDetailPages/hooks/usePollServerForBuilderActions";
import useSyncBuilderActionsToServer from "@hooks/useSyncBuilderActionsToServer";
import { BuilderActionFragment } from "@src/components/libraryItemDetailPages/hooks/usePollServerForBuilderActions.generated";
import { cloneDeep } from "lodash";
import shouldActionsBeDeduped from "@src/components/libraryItemDetailPages/module/utils/shouldActionsBeDeduped";
import { useKeystrokes } from "@hooks/useKeystrokes";
import { useRouter } from "next/router";
import { uuid4 } from "@utils/strings";
import { atom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { selectedContentIdsAtom } from "@src/components/libraryItemDetailPages/course/atoms";

export interface BuilderActionContextState {
  dispatchAction: (
    contentIds: BuilderActionContentIds,
    payload: ActionPayload | ActionPayload[],
    skipAddToUndoStack?: boolean,
  ) => void;
  undoAction: () => void;
  redoAction: () => void;
}

export const BuilderActionContext = React.createContext<
  BuilderActionContextState | undefined
>(undefined);

export type BuilderActionContentIds = {
  pathId?: number;
  courseId?: number;
  skillId?: number;
};

export type BuilderActionContextContent = {
  contentIds: BuilderActionContentIds;
  initialLastAppliedBuilderActionId: number | null | undefined;
};

export type BuilderActionContextProviderProps = {
  content: BuilderActionContextContent[];
  applyActionFromServer: (action: BuilderActionPartial) => void;
  applyActionFromClient: (action: BuilderActionPartial) => void;
  children: React.ReactNode;
};

export type BuilderActionPartial = Pick<
  BuilderActionFragment,
  "addedPathContentMembership"
> & {
  payload: Omit<BuilderActionInput, "clientId" | "uuid">;
};

export type ActionPayload = {
  action: BuilderActionPartial;
  inverseAction: BuilderActionPartial | null;
};

type UndoStackItemContainer = {
  uuid: string;
  contentIds: BuilderActionContentIds;
  undoStackItems: ActionPayload[];
  url: string;
};

type State = {
  undoStack: UndoStackItemContainer[];
  redoStack: UndoStackItemContainer[];
};
const stateAtom = atom<State>({
  undoStack: [],
  redoStack: [],
});
const stateForSelectedCourseVersionAtom = atom((get) => {
  const state = get(stateAtom);
  const selectedContentIds = get(selectedContentIdsAtom);
  return {
    undoStack: state.undoStack.filter((item) => {
      return (
        item.contentIds.pathId ||
        contentIdsAreEqual(item.contentIds, selectedContentIds)
      );
    }),
    redoStack: state.redoStack.filter((item) => {
      return (
        item.contentIds.pathId ||
        contentIdsAreEqual(item.contentIds, selectedContentIds)
      );
    }),
  };
});
export const undoAvailableCountAtom = atom(
  (get) => get(stateForSelectedCourseVersionAtom).undoStack.length,
);
export const redoAvailableCountAtom = atom(
  (get) => get(stateForSelectedCourseVersionAtom).redoStack.length,
);

const BuilderActionContextProvider: FC<BuilderActionContextProviderProps> = (
  props,
) => {
  const { addToSyncQueue } = useSyncBuilderActionsToServer();
  const applyActionFromClient = props.applyActionFromClient;

  const pollArgs = useMemo(
    () => ({
      applyActionFromServerFn: props.applyActionFromServer,
      contentToPollActionsFor: props.content,
    }),
    [props.applyActionFromServer, props.content],
  );

  usePollServerForBuilderActions(pollArgs);
  const router = useRouter();
  const addDedupedItemToUndoStack = useAtomCallback(
    useCallback(
      (get, set, item: UndoStackItemContainer) => {
        set(stateAtom, (prevState) => {
          const newUndoStackItem = cloneDeep(item);
          const newState = cloneDeep(prevState);
          newState.redoStack = [];
          const prevUndoStackItem =
            newState.undoStack[newState.undoStack.length - 1];
          if (
            prevUndoStackItem &&
            shouldMergeUndoStackItems(prevUndoStackItem, newUndoStackItem)
          ) {
            const mergedPrevAndNewUndoStackItem: UndoStackItemContainer = {
              uuid: newUndoStackItem.uuid,
              contentIds: newUndoStackItem.contentIds,
              undoStackItems: [
                mergeUndoRedoActions(
                  prevUndoStackItem?.undoStackItems[0],
                  newUndoStackItem.undoStackItems[0],
                ),
              ],
              url: router.asPath,
            };
            newState.undoStack.pop();
            newState.undoStack.push(mergedPrevAndNewUndoStackItem);
          } else {
            newState.undoStack.push(newUndoStackItem);
          }
          return newState;
        });
      },
      [router.asPath],
    ),
  );
  useKeystrokes({
    z: (event) => {
      if (event.metaKey || event.ctrlKey) {
        event.preventDefault();
        if (event.shiftKey) {
          redoAction();
        } else {
          undoAction();
        }
      }
    },
  });
  const dispatchAction: BuilderActionContextState["dispatchAction"] =
    useCallback(
      (contentIds, item, skipAddToUndoStack) => {
        const undoStackItems = Array.isArray(item) ? item : [item];
        undoStackItems.forEach((x) => {
          addToSyncQueue(x.action);
          applyActionFromClient(x.action);
        });
        if (skipAddToUndoStack) return;
        addDedupedItemToUndoStack({
          uuid: uuid4(),
          contentIds,
          undoStackItems,
          url: router.asPath,
        });
      },
      [
        addDedupedItemToUndoStack,
        addToSyncQueue,
        applyActionFromClient,
        router.asPath,
      ],
    );
  const undoAction: BuilderActionContextState["undoAction"] = useAtomCallback(
    useCallback(
      (get, set) => {
        const state = get(stateAtom);
        const newState = cloneDeep(state);
        const stateForSelectedCourseVersion = get(
          stateForSelectedCourseVersionAtom,
        );
        const undoItem =
          stateForSelectedCourseVersion.undoStack[
            stateForSelectedCourseVersion.undoStack.length - 1
          ];
        if (!undoItem) return;
        if (router.asPath !== undoItem.url) {
          // @ts-ignore
          router.push(undoItem.url);
        }
        [...undoItem.undoStackItems].reverse().forEach((x) => {
          if (x.inverseAction) {
            addToSyncQueue(x.inverseAction);
            applyActionFromClient(x.inverseAction);
          }
        });
        newState.undoStack = newState.undoStack.filter(
          (x) => x.uuid !== undoItem.uuid,
        );
        newState.redoStack.push(undoItem);
        set(stateAtom, newState);
      },
      [addToSyncQueue, applyActionFromClient, router],
    ),
  );
  const redoAction: BuilderActionContextState["redoAction"] = useAtomCallback(
    useCallback(
      (get, set) => {
        const state = get(stateAtom);
        const newState = cloneDeep(state);
        const stateForSelectedCourseVersion = get(
          stateForSelectedCourseVersionAtom,
        );
        const redoItem =
          stateForSelectedCourseVersion.redoStack[
            stateForSelectedCourseVersion.redoStack.length - 1
          ];
        if (!redoItem) return;
        if (router.asPath !== redoItem.url) {
          // @ts-ignore
          router.push(redoItem.url);
        }
        redoItem.undoStackItems.forEach((x) => {
          addToSyncQueue(x.action);
          applyActionFromClient(x.action);
        });
        newState.redoStack = newState.redoStack.filter(
          (x) => x.uuid !== redoItem.uuid,
        );
        newState.undoStack.push(redoItem);
        set(stateAtom, newState);
      },
      [addToSyncQueue, applyActionFromClient, router],
    ),
  );
  const contextState: BuilderActionContextState = useMemo(() => {
    return {
      dispatchAction,
      undoAction,
      redoAction,
    };
  }, [dispatchAction, redoAction, undoAction]);
  return (
    <BuilderActionContext.Provider value={contextState}>
      {props.children}
    </BuilderActionContext.Provider>
  );
};

export default BuilderActionContextProvider;

const shouldMergeUndoStackItems = (
  prevItem: UndoStackItemContainer,
  nextItem: UndoStackItemContainer,
): boolean => {
  const prevItemFirstAction = prevItem.undoStackItems[0];
  const nextItemFirstAction = nextItem.undoStackItems[0];
  if (
    !prevItemFirstAction ||
    !nextItemFirstAction ||
    prevItem.undoStackItems.length > 1 ||
    nextItem.undoStackItems.length > 1
  )
    return false;
  return shouldActionsBeDeduped(
    prevItemFirstAction.action,
    nextItemFirstAction.action,
  );
};

const mergeUndoRedoActions = (
  prevUndoItem: ActionPayload,
  nextUndoItem: ActionPayload,
): ActionPayload => {
  return {
    action: nextUndoItem.action,
    inverseAction: prevUndoItem.inverseAction,
  };
};

const contentIdsAreEqual = (
  a: BuilderActionContentIds,
  b: BuilderActionContentIds,
): boolean => {
  if (a.pathId && b.pathId) return a.pathId === b.pathId;
  else if (a.courseId && b.courseId) return a.courseId === b.courseId;
  else if (a.skillId && b.skillId) return a.skillId === b.skillId;
  else return false;
};
