import { PathValidationErrors } from "@src/components/libraryItemDetailPages/module/contexts/PathBuilderContext";
import {
  courseHasValidationErrors,
  courseValidationErrorCount,
  validateDraftCourse,
} from "@src/components/libraryItemDetailPages/course/utils/publishValidation";
import {
  hasSkillValidationErrors,
  skillValidationErrorCount,
  validateSkillVersion,
} from "@builder/skill/utils/validation";
import { ValidatePath_PathVersionFragment } from "@src/components/libraryItemDetailPages/module/hooks/useValidatePathAndOpenPublishModal.generated";
import { emptyPathValidationErrors } from "@src/components/libraryItemDetailPages/module/hooks/useValidatePathAndOpenPublishModal";

export const pathHasContentValidationErrors = (
  validationErrors: PathValidationErrors,
): boolean => {
  return pathContentValidationErrorCount(validationErrors) > 0;
};

export const pathContentValidationErrorCount = (
  validationErrors: PathValidationErrors,
): number => {
  return (
    pathCourseValidationErrorCount(validationErrors) +
    pathCourseOutlineValidationErrorCount(validationErrors) +
    pathSkillValidationErrorCount(validationErrors)
  );
};

export const pathCourseValidationErrorCount = (
  validationErrors: PathValidationErrors,
): number => {
  return Array.from(
    validationErrors.courseIdToValidationErrors.values(),
  ).reduce((acc, courseValidationErrors) => {
    return acc + courseValidationErrorCount(courseValidationErrors);
  }, 0);
};

export const pathCourseOutlineValidationErrorCount = (
  validationErrors: PathValidationErrors,
): number => {
  return validationErrors.courseOutlineIds.length;
};

const pathSkillValidationErrorCount = (
  validationErrors: PathValidationErrors,
): number => {
  return Array.from(validationErrors.skillIdToValidationErrors.values()).reduce(
    (acc, skillValidationErrors) => {
      return acc + skillValidationErrorCount(skillValidationErrors);
    },
    0,
  );
};

export const validatePath = (
  pv: ValidatePath_PathVersionFragment,
): PathValidationErrors => {
  const newValidationErrors: PathValidationErrors = {
    ...emptyPathValidationErrors(),
    duplicateMembershipUuids: getMembershipUuidsWithDuplicateContent(pv),
  };
  let prevMem = null;
  for (const mem of pv.pathContentMemberships) {
    if (mem.course) {
      const newCourseValidationErrors = validateDraftCourse(
        mem.course.draftVersion,
      ).validationErrors;
      if (courseHasValidationErrors(newCourseValidationErrors)) {
        newValidationErrors.courseIdToValidationErrors.set(
          mem.course.id,
          validateDraftCourse(mem.course.draftVersion).validationErrors,
        );
      }
    } else if (mem.courseOutline) {
      newValidationErrors.courseOutlineIds.push(mem.courseOutline.id);
    } else if (mem.skill?.draftVersion) {
      const newSkillValidationErrors = validateSkillVersion(
        mem.skill.draftVersion,
      );
      if (hasSkillValidationErrors(newSkillValidationErrors)) {
        newValidationErrors.skillIdToValidationErrors.set(
          mem.skill.id,
          newSkillValidationErrors,
        );
      }
    } else if (mem.sectionBreak) {
      if (prevMem?.sectionBreak) {
        newValidationErrors.emptySectionBreakUuids.add(
          prevMem.sectionBreak.uuid,
        );
      }
    }
    prevMem = mem;
  }
  const lastMem =
    pv.pathContentMemberships[pv.pathContentMemberships.length - 1];
  if (lastMem?.sectionBreak) {
    newValidationErrors.emptySectionBreakUuids.add(lastMem.sectionBreak.uuid);
  }
  return newValidationErrors;
};

const getMembershipUuidsWithDuplicateContent = (
  pathVersion: ValidatePath_PathVersionFragment,
): Set<string> => {
  const ret = new Set<string>();
  const seenCourseIds = new Set<number>();
  const seenSkillIds = new Set<number>();
  const seenFileIds = new Set<string>();
  for (const mem of pathVersion.pathContentMemberships) {
    if (mem.course) {
      if (seenCourseIds.has(mem.course.id)) {
        ret.add(mem.uuid);
      }
      seenCourseIds.add(mem.course.id);
    } else if (mem.skill) {
      if (seenSkillIds.has(mem.skill.id)) {
        ret.add(mem.uuid);
      }
      seenSkillIds.add(mem.skill.id);
    } else if (mem.trainingResource) {
      if (seenFileIds.has(mem.trainingResource.id)) {
        ret.add(mem.uuid);
      }
      seenFileIds.add(mem.trainingResource.id);
    }
  }
  return ret;
};
