import useDispatchPathBuilderAction, {
  PathUndoStackItem,
} from "@src/components/libraryItemDetailPages/module/hooks/useDispatchPathBuilderAction";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import { pathVersionAtom } from "../atoms";

const useDispatchRemoveAllCourseOutlinesInPath = (): (() => void) => {
  const dispatch = useDispatchPathBuilderAction();
  return useAtomCallback(
    useCallback(
      (get) => {
        const pathVersion = get(pathVersionAtom);
        if (!pathVersion) return;
        const removeMemberships: PathUndoStackItem[] = [];
        pathVersion.pathContentMemberships.forEach((membership) => {
          if (membership?.courseOutline) {
            removeMemberships.push({
              action: {
                payload: {
                  removePathContentMembershipAction: {
                    pathContentMembershipUuid: membership.uuid,
                  },
                },
              },
              inverseAction: null,
            });
          }
        });
        dispatch(removeMemberships);
      },
      [dispatch],
    ),
  );
};

export default useDispatchRemoveAllCourseOutlinesInPath;
