import { BuilderActionPartial } from "@contexts/BuilderActionContext";
import { cloneDeep } from "lodash";
import { isRichTextEmpty } from "@src/components/libraryItemDetailPages/course/utils/utils";
import { SkillBuilder_SkillVersionFragment } from "../SkillDetailPage.generated";

export type SkillValidationErrors = {
  hasNoName: boolean;
  stepsWithEmptyText: Set<string>;
};

export const emptySkillValidationErrors = (): SkillValidationErrors => {
  return {
    hasNoName: false,
    stepsWithEmptyText: new Set(),
  };
};

export const applyBuilderActionToSkillValidationErrors = (args: {
  validationErrors: SkillValidationErrors;
  action: BuilderActionPartial;
}): SkillValidationErrors => {
  const sba = args.action.payload.skillBuilderAction;
  if (!sba) return args.validationErrors;
  const validationErrors = cloneDeep(args.validationErrors);
  if (sba.setStepTextAction) {
    validationErrors.stepsWithEmptyText.delete(sba.setStepTextAction.id);
  } else if (sba.setSkillNameAction) {
    validationErrors.hasNoName = false;
  }
  return validationErrors;
};

export const validateSkillVersion = (
  sv: SkillBuilder_SkillVersionFragment,
): SkillValidationErrors => {
  const ret = emptySkillValidationErrors();
  sv.verificationSteps.forEach((s) => {
    if (isRichTextEmpty(s.text?.en || "")) {
      ret.stepsWithEmptyText.add(s.id);
    }
  });
  if (isRichTextEmpty(sv.skill.libraryItem.name?.en || "")) {
    ret.hasNoName = true;
  }
  return ret;
};

export const skillValidationErrorCount = (
  errors: SkillValidationErrors,
): number => {
  return errors.stepsWithEmptyText.size + (errors.hasNoName ? 1 : 0);
};

export const hasSkillValidationErrors = (
  errors: SkillValidationErrors,
): boolean => {
  return skillValidationErrorCount(errors) > 0;
};
