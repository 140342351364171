import {
  BuilderPathVersionQuery,
  PathBuilderContentMembershipFragment,
} from "./ModuleDetailPageContainer.generated";
import { atom } from "jotai";
import { selectAtom } from "jotai/utils";
import { isEqual } from "lodash";
import { getMembershipSections } from "./utils/getMembershipSections";
import { PathValidationErrors } from "./contexts/PathBuilderContext";
import { emptyPathValidationErrors } from "@src/components/libraryItemDetailPages/module/hooks/useValidatePathAndOpenPublishModal";
import {
  ContentMembershipItemData,
  CourseContentMembershipItemData,
  courseOutlineContentMembershipItemData,
  FileContentMembershipItemData,
  getMembershipScreenId,
  PathMembershipItemData,
  SectionBreakMembershipItemData,
  SkillContentMembershipItemData,
} from "./hooks/usePathMembershipItems";
import { BuilderActionContextContent } from "@src/contexts/BuilderActionContext";
import { skillVersionAtom } from "../skill/atoms";
import {
  emptySkillValidationErrors,
  skillValidationErrorCount,
} from "../skill/utils/validation";
import { emptyCourseValidationErrors } from "../course/types/validation";
import { courseValidationErrorCount } from "../course/utils/publishValidation";

export const pathVersionAtom = atom<
  BuilderPathVersionQuery["PathVersion"] | undefined
>(undefined);

export const lastBuilderActionIdAtom = selectAtom(
  pathVersionAtom,
  (val) => val?.path?.lastBuilderActionId,
  isEqual,
);
export const builderActionContentAtom = atom<BuilderActionContextContent[]>(
  (get) => {
    const ret: BuilderActionContextContent[] = [];
    const pathVersionPathId = get(pathVersionPathIdAtom);
    const lastBuilderActionId = get(lastBuilderActionIdAtom);
    if (pathVersionPathId) {
      ret.push({
        contentIds: { pathId: pathVersionPathId },
        initialLastAppliedBuilderActionId: lastBuilderActionId,
      });
    }
    const pathContentMemberships = get(pathContentMembershipsAtom);
    pathContentMemberships?.forEach((m) => {
      if (m.course) {
        ret.push({
          contentIds: { courseId: m.course.id },
          initialLastAppliedBuilderActionId: m.course.lastBuilderActionId,
        });
      } else if (m.skill) {
        ret.push({
          contentIds: { skillId: m.skill.id },
          initialLastAppliedBuilderActionId: m.skill.lastBuilderActionId,
        });
      }
    });
    return ret;
  },
);
export const pathContentMembershipsAtom = selectAtom(
  pathVersionAtom,
  (pv) => pv?.pathContentMemberships,
  isEqual,
);
export const pathVersionNameAtom = selectAtom(
  pathVersionAtom,
  (pv) => pv?.path?.libraryItem?.name,
  isEqual,
);
export const pathVersionDescriptionAtom = selectAtom(
  pathVersionAtom,
  (pv) => pv?.path?.libraryItem?.description,
  isEqual,
);
export const pathVersionIdAtom = selectAtom(
  pathVersionAtom,
  (pv) => pv?.id,
  isEqual,
);

export const pathVersionDescriptionEnAtom = selectAtom(
  pathVersionDescriptionAtom,
  (val) => val?.en ?? "",
  isEqual,
);

export const pathVersionCoverImageAtom = selectAtom(
  pathVersionAtom,
  (pv) => pv?.path?.libraryItem?.coverImage,
  isEqual,
);

export const pathVersionNameEnAtom = selectAtom(
  pathVersionNameAtom,
  (val) => val?.en,
  isEqual,
);
export const pathVersionPathAtom = selectAtom(
  pathVersionAtom,
  (val) => val?.path,
  isEqual,
);

export const pathVersionPathIdAtom = selectAtom(
  pathVersionPathAtom,
  (val) => val?.id,
  isEqual,
);

export const pathValidationErrorsAtom = atom<PathValidationErrors>(
  emptyPathValidationErrors(),
);
export const draftVersionSelectedAtom = selectAtom(
  pathVersionAtom,
  (pathVersion) => {
    return pathVersion?.id === pathVersion?.path.draftVersion.id;
  },
  isEqual,
);
export const getPathMembershipValidationErrorCount = (
  mem: PathBuilderContentMembershipFragment,
  pathValidationErrors: PathValidationErrors,
): number => {
  let count = 0;
  if (mem.course) {
    const courseValidationErrors =
      pathValidationErrors.courseIdToValidationErrors?.get(mem.course?.id) ||
      emptyCourseValidationErrors();
    count += courseValidationErrorCount(courseValidationErrors);
  } else if (mem.courseOutline) {
    const hasCourseOutlineErrors =
      pathValidationErrors.courseOutlineIds.length > 0;
    count += hasCourseOutlineErrors ? 1 : 0;
  } else if (mem.skill) {
    const skillValidationErrors =
      pathValidationErrors.skillIdToValidationErrors?.get(mem.skill?.id) ||
      emptySkillValidationErrors();
    count += skillValidationErrorCount(skillValidationErrors);
  }
  return count;
};

type Return = {
  membershipItems: PathMembershipItemData[];
};
export const pathMembershipItemsAtom = atom<Return>((get) => {
  const pathVersion = get(pathVersionAtom);
  const validationErrors = get(pathValidationErrorsAtom);
  const items: PathMembershipItemData[] = [];
  if (!pathVersion) return { membershipItems: items };
  for (
    let memIndex = 0;
    memIndex < pathVersion.pathContentMemberships.length;
    memIndex++
  ) {
    const membership = pathVersion.pathContentMemberships[memIndex];
    const memValidationErrorCount = getPathMembershipValidationErrorCount(
      membership,
      validationErrors || emptyPathValidationErrors(),
    );
    if (membership.sectionBreak) {
      items.push(
        new SectionBreakMembershipItemData(
          getMembershipScreenId(membership.uuid),
          membership.fractionPosition,
          pathVersion,
          membership,
          memValidationErrorCount,
          membership.sectionBreak,
        ),
      );
    } else {
      if (membership.course?.id) {
        items.push(
          new CourseContentMembershipItemData(
            getMembershipScreenId(membership.uuid),
            membership.fractionPosition,
            pathVersion,
            membership,
            memValidationErrorCount,
            membership.course,
          ),
        );
      } else if (membership.trainingResource?.id) {
        items.push(
          new FileContentMembershipItemData(
            getMembershipScreenId(membership.uuid),
            membership.fractionPosition,
            pathVersion,
            membership,
            memValidationErrorCount,
            membership.trainingResource,
          ),
        );
      } else if (membership.skill?.id) {
        items.push(
          new SkillContentMembershipItemData(
            getMembershipScreenId(membership.uuid),
            membership.fractionPosition,
            pathVersion,
            membership,
            memValidationErrorCount,
            membership.skill,
          ),
        );
      } else if (membership.courseOutline?.id) {
        items.push(
          new courseOutlineContentMembershipItemData(
            getMembershipScreenId(membership.uuid),
            membership.fractionPosition,
            pathVersion,
            membership,
            memValidationErrorCount,
            membership.courseOutline,
          ),
        );
      }
    }
  }
  return {
    membershipItems: items,
  };
});
export const membershipItemsAtom = selectAtom(
  pathMembershipItemsAtom,
  (val) => {
    return val?.membershipItems;
  },
  isEqual,
);
export const membershipSectionsAtom = selectAtom(
  membershipItemsAtom,
  (membershipItems) => {
    return getMembershipSections(membershipItems);
  },
  isEqual,
);
export const contentMembershipItemsAtom = selectAtom(
  membershipItemsAtom,
  (membershipItems) => {
    return membershipItems?.filter(
      (item): item is ContentMembershipItemData =>
        item instanceof ContentMembershipItemData,
    );
  },
  isEqual,
);
export const selectedMembershipUuidsAtom = atom<string[]>([]);
export const selectedMembershipItemsAtom = atom((get) => {
  const selectedMembershipUuids = get(selectedMembershipUuidsAtom);
  const membershipItems = get(
    selectAtom(
      pathMembershipItemsAtom,
      (pathMembershipItems) => pathMembershipItems.membershipItems,
      isEqual,
    ),
  );
  return membershipItems.filter(
    (item): item is ContentMembershipItemData =>
      selectedMembershipUuids.includes(item.id) &&
      item instanceof ContentMembershipItemData,
  );
});
export const activeOutlineUuidAtom = atom<string | null>(null);
export const publishedVersionSelectedAtom = selectAtom(
  pathVersionAtom,
  (pathVersion) => {
    return (
      pathVersion && pathVersion.id == pathVersion?.path.publishedVersion?.id
    );
  },
);

export const skillValidationErrorsAtom = atom((get) => {
  const draftVersionSelected = get(draftVersionSelectedAtom);
  const skillVersion = get(skillVersionAtom);
  const validationErrors = get(pathValidationErrorsAtom);
  if (!draftVersionSelected) return emptySkillValidationErrors();
  const skillId = skillVersion?.skill.id ?? -1;
  const map = validationErrors.skillIdToValidationErrors;
  return map?.get(skillId) || emptySkillValidationErrors();
});
