import React, { FC } from "react";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import DeprecatedIcon, {
  DeprecatedIconType,
} from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import SimpleDropdown from "@src/components/libraryItemDetailPages/course/SimpleDropdown";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import Text from "@ui/text";
import ContextMenuItem from "@src/deprecatedDesignSystem/components/ContextMenu/ContextMenuItem";
import * as RadixPopover from "@radix-ui/react-popover";
import { Tooltip, TooltipContent, TooltipTrigger } from "@ui/tooltip";

export const SETTINGS_LABEL_WIDTH = 200;

type Props = {
  rows: BuilderSettingsTableRowProps[];
  disabled?: boolean;
  fontColor?: string;
};

const BuilderSettingsTable: FC<Props> = (props) => {
  return (
    <AutoLayout
      direction={"vertical"}
      alignSelf={"stretch"}
      flex={1}
      spaceBetweenItems={8}
    >
      {props.rows.map((row) => {
        return (
          <BuilderSettingsTableRow
            {...row}
            fontColor={props.fontColor}
            disabled={row.disabled || props.disabled}
            key={row.key}
          />
        );
      })}
    </AutoLayout>
  );
};

export default BuilderSettingsTable;

export type BuilderSettingsTableRowProps = {
  key: string;
  iconType: DeprecatedIconType;
  label: string;
  tooltipDescription?: string;
  value?: React.ReactNode;
  disabled?: boolean;
  fontColor?: string;
  editStatePopoverContent?: React.ReactNode;
  simpleDropdownProps?: Partial<React.ComponentProps<typeof SimpleDropdown>>;
};

const BuilderSettingsTableRow: FC<BuilderSettingsTableRowProps> = (props) => {
  return (
    <AutoLayout alignSelf="stretch" alignmentVertical={"center"}>
      <Tooltip>
        <TooltipTrigger asChild>
          <AutoLayout
            paddingRight={4}
            alignmentVertical={"center"}
            alignmentHorizontal="left"
            width={SETTINGS_LABEL_WIDTH}
          >
            <DeprecatedIcon
              iconSize={24}
              containerSize={24}
              type={props.iconType}
              color={deprecatedTones.gray7}
              style={{ marginRight: 8 }}
            />
            <AutoLayout flex={1}>
              <Text
                type="P2"
                fontWeight="Medium"
                color={props.fontColor || deprecatedTones.gray8}
              >
                {props.label}
              </Text>
            </AutoLayout>
          </AutoLayout>
        </TooltipTrigger>
        {props.tooltipDescription && (
          <TooltipContent
            side="left"
            style={{
              backgroundColor: deprecatedTones.black,
              color: deprecatedTones.white,
            }}
          >
            {props.tooltipDescription}
          </TooltipContent>
        )}
      </Tooltip>
      <AutoLayout
        flex={1}
        alignmentVertical="center"
        maxWidth={SETTINGS_LABEL_WIDTH}
      >
        <SimpleDropdown
          trigger={
            <AutoLayout
              flex={1}
              alignSelf="stretch"
              alignmentVertical={"center"}
              paddingVertical={3}
              paddingLeft={9}
              paddingRight={16}
              borderRadius={8}
              style={{
                flexShrink: 0,
                flexWrap: "wrap",
                userSelect: "none",
                position: "relative",
                transition: "background-color 0.25s bezier",
                cursor: props.disabled ? "not-allowed" : "pointer",
              }}
              hoverStyle={
                !props.disabled
                  ? {
                      backgroundColor: deprecatedTones.gray5Alpha,
                    }
                  : undefined
              }
            >
              <Text
                type="P2"
                fontWeight="SemiBold"
                color={
                  !props.disabled
                    ? deprecatedTones.black
                    : deprecatedTones.gray8
                }
              >
                {props.value}
              </Text>
            </AutoLayout>
          }
          content={!props.disabled && props.editStatePopoverContent}
          radixContentProps={{
            collisionPadding: 8,
            align: "end",
          }}
          {...props.simpleDropdownProps}
        />
      </AutoLayout>
    </AutoLayout>
  );
};

type BuilderSettingsTableContextMenuItemProps = {
  label: string;
  onClick: () => void;
};

export const BuilderSettingsTableContextMenuItem: FC<
  BuilderSettingsTableContextMenuItemProps
> = (props) => {
  return (
    <RadixPopover.Close asChild>
      <AutoLayout alignSelf={"stretch"}>
        <ContextMenuItem
          label={props.label}
          align={"right"}
          onSelect={props.onClick}
        />
      </AutoLayout>
    </RadixPopover.Close>
  );
};
