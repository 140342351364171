import SelectInput from "@components/ui/SelectInput";
import { DueDateInput } from "@src/types.generated";
import { deprecatedColors } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { getDigits } from "@utils/strings";
import { css, StyleSheet } from "aphrodite";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { FC } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isNotNullOrUndefined } from "@utils/typeguards";

type Props = {
  dueDate: string | null | undefined;
  dueDateDays: number | null | undefined;
  setDueDate: (input: DueDateInput) => void;
};

const isInFuture = (date: Date) => {
  return date >= new Date(new Date().toDateString());
};

export const DueDatePicker: FC<Props> = ({
  dueDate,
  setDueDate,
  dueDateDays,
}) => {
  const type = dueDate
    ? "date"
    : isNotNullOrUndefined(dueDateDays)
      ? "days"
      : "none";
  return (
    <div className={css(styles.container)}>
      <SelectInput
        inputStyle="inline"
        value={type}
        style={{ marginRight: 12 }}
        choices={[
          { label: "None", value: "none" },

          { label: "Date", value: "date" },
          { label: "Days", value: "days" },
        ]}
        onChange={({ value }) => {
          if (value === "none") {
            setDueDate({
              dueDate: null,
              dueDateDays: null,
            });
          } else if (value === "date") {
            setDueDate({
              dueDate: new Date().toISOString(),
              dueDateDays: null,
            });
          } else if (value === "days") {
            setDueDate({
              dueDate: null,
              dueDateDays: 1,
            });
          }
        }}
      />
      {isNotNullOrUndefined(dueDateDays) && (
        <input
          min="0"
          value={dueDateDays}
          className={css(styles.input)}
          style={{
            padding: 0,
            width: 32,
            height: 20,
            margin: 0,
            textAlign: "center",
          }}
          onChange={(e) => {
            const digits = getDigits(e.target.value);
            if (digits.length === 0) {
              setDueDate({
                dueDate: null,
                dueDateDays: 3,
              });
              return;
            }
            const floor = Math.max(Number(digits), 0);
            const value = Math.min(floor, 90);
            setDueDate({
              dueDate: null,
              dueDateDays: value,
            });
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setDueDate({
                dueDate: null,
                dueDateDays: dueDateDays || 0,
              });
            }
          }}
          onBlur={() =>
            setDueDate({
              dueDate: null,
              dueDateDays: dueDateDays || 0,
            })
          }
        />
      )}
      {isNotNullOrUndefined(dueDate) && (
        // @ts-ignore-next-line
        <DatePicker
          className={css(styles.input, styles.dueDateInput)}
          selected={parseISO(dueDate)}
          filterDate={isInFuture}
          onChange={(date: Date | null, event) => {
            event?.stopPropagation();
            if (date) {
              setDueDate({
                dueDate: format(date, "yyyy-MM-dd"),
                dueDateDays: null,
              });
            } else {
              setDueDate({
                dueDate: null,
                dueDateDays: null,
              });
            }
          }}
          placeholderText="MM/DD/YYYY"
        />
      )}
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    borderRadius: 8,
    alignItems: "center",
  },
  input: {
    padding: 0,
    border: "none",
    borderBottom: `1px dashed ${deprecatedColors.outline}`,
  },
  dueDateInput: {
    width: 90,
    fontSize: 15,
  },
});
