import React, { FC, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

export interface ClientIdContextState {
  clientId: string;
}

export const ClientIdContext = React.createContext<
  ClientIdContextState | undefined
>(undefined);

type Props = {
  children: React.ReactNode;
};

const ClientIdContextProvider: FC<Props> = ({ children }) => {
  const clientId = useMemo(() => uuidv4(), []);
  const state = useMemo(() => ({ clientId }), [clientId]);
  return (
    <ClientIdContext.Provider value={state}>
      {children}
    </ClientIdContext.Provider>
  );
};

export default ClientIdContextProvider;
