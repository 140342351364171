import {
  BuilderActionContext,
  BuilderActionPartial,
} from "@contexts/BuilderActionContext";
import { BuilderActionFragment } from "@src/components/libraryItemDetailPages/hooks/usePollServerForBuilderActions.generated";
import { PathBuilderActionInput } from "@src/types.generated";
import { useCallback, useContext } from "react";
import { useAtomCallback } from "jotai/utils";
import { pathVersionPathIdAtom } from "../atoms";

type DispatchPathBuilderAction = (
  item: PathUndoStackItem | PathUndoStackItem[],
  skipAddToUndoStack?: boolean,
) => void;

export type PathUndoStackItem = {
  action: PathBuilderActionPartial;
  inverseAction: PathBuilderActionPartial | null;
};

export type PathBuilderActionPartial = Pick<
  BuilderActionFragment,
  "addedPathContentMembership"
> & {
  payload: Omit<PathBuilderActionInput, "pathId">;
};

const useDispatchPathBuilderAction = (): DispatchPathBuilderAction => {
  const builderActionContext = useContext(BuilderActionContext);
  return useAtomCallback(
    useCallback(
      (get, set, item, skipAddToUndoStack) => {
        const pathId = get(pathVersionPathIdAtom);
        if (!pathId || !builderActionContext) return;
        const itemList = item instanceof Array ? item : [item];
        builderActionContext.dispatchAction(
          { pathId },
          itemList.map((x) => ({
            action: convertToBuilderActionPartial(x.action, pathId),
            inverseAction: x.inverseAction
              ? convertToBuilderActionPartial(x.inverseAction, pathId)
              : null,
          })),
          !!skipAddToUndoStack,
        );
      },
      [builderActionContext],
    ),
  );
};

const convertToBuilderActionPartial = (
  pathAction: PathBuilderActionPartial,
  pathId: number,
): BuilderActionPartial => {
  return {
    ...pathAction,
    payload: {
      pathBuilderAction: {
        pathId,
        ...pathAction.payload,
      },
    },
  };
};

export default useDispatchPathBuilderAction;
