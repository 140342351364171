import { gql } from "@apollo/client";
import useContentPermissions from "@src/components/libraryItemDetailPages/hooks/useContentPermissions";
import {
  PublishPathModalFragment,
  useSavePathModalQuery,
  useUpdatePathSettingsAndPublishMutation,
} from "@src/components/libraryItemDetailPages/module/build/PublishPathModal.generated";
import { getDueDateInputValue } from "@components/contentDetail/courseDetail/overview/EditCourseDueDate";
import Modal from "@components/modals/Modal";
import { DueDatePicker } from "@components/modals/courseEnrollment/DueDatePicker";
import Spacer from "@components/ui/Spacer";
import Text from "@ui/text";
import { useModal } from "@hooks/useModal";
import { useToast } from "@hooks/useToast";
import UpgradePlanCTA from "@src/components/people/personDetail/modals/inviteOrEditUserModal/UpgradePlanCTA";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import useCanPublishMoreModules from "@src/hooks/billing/useCanPublishMoreModules";
import {
  DueDateInputValue,
  GraphqlOperations,
  PathSettingsInput,
} from "@src/types.generated";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { launchConfetti } from "@utils/confetti";
import { StyleSheet, css } from "aphrodite";
import { FC, useMemo, useState } from "react";
import PublishModalLibraryAccessPicker from "../../shared/PublishModalLibraryAccessPicker";
import { AutomationItem } from "@src/components/sidebars/shared/constants";
import useGetUserSelectionInputFromAutomationItem from "@src/components/contentLibrary/assignmentModals/hooks/useGetUserSelectionInputFromAutomationItem";
import useUserSelectionInputState from "@src/components/contentLibrary/assignmentModals/hooks/useUserSelectionInputState";
import useActionPermissions from "@src/hooks/useActionPermissions";
import { useUpdateAutomationMutation } from "@src/components/contentDetail/premiumPathDetail/AutoReassignToggle.generated";

type Props = {
  pathId: number;
};

const PublishPathModal: FC<Props> = ({ pathId }) => {
  const { data } = useSavePathModalQuery({
    variables: {
      pathId,
    },
  });
  const path = data?.Path;
  if (!path) {
    return null;
  }

  return <PublishPathModalInner path={path} />;
};

type InnerProps = {
  path: PublishPathModalFragment;
};

const PublishPathModalInner: FC<InnerProps> = ({ path }) => {
  const canPublishMoreModules = useCanPublishMoreModules(path.id);
  const permissions = useContentPermissions({ pathId: path.id });
  const [dueDate, setDueDate] = useState<DueDateInputValue>(
    getDueDateInputValue(
      path.libraryItem.dueDate || path.libraryItem.dueDateDays,
    ),
  );
  const pathSettingsInput: PathSettingsInput = useMemo(() => {
    return {
      dueDate: {
        value: dueDate.value,
      },
    };
  }, [dueDate.value]);
  const { addToast, addErrorToast } = useToast();
  const { closeModal } = useModal();
  const [
    updatePathSettingsAndPublishMutation,
    { loading: loadingUpdatePathSettingsAndPublishMutation },
  ] = useUpdatePathSettingsAndPublishMutation({
    refetchQueries: [GraphqlOperations.Query.BuilderPathVersion],
    variables: {
      pathId: path.id,
      pathSettingsInput,
    },
    onCompleted: (data) => {
      if (data.publishPathV2.success) {
        closeModal();
        launchConfetti();
        addToast({ message: `Module published`, iconType: "star" });
      } else {
        addErrorToast({
          message: "An unexpected error occurred while publishing",
          callsite: "publish_path_modal",
        });
      }
    },
    onError: () => {
      addErrorToast({
        message: "An unexpected error occurred while publishing",
        callsite: "publish_path_modal",
      });
    },
  });
  const automationItem: AutomationItem | null = useMemo(
    () =>
      path.libraryItem.accessAutomation
        ? {
            automation: path.libraryItem.accessAutomation,
            contentName: path.libraryItem.name.en,
            __typename: "Path",
          }
        : null,
    [path.libraryItem.accessAutomation, path.libraryItem.name.en],
  );
  const initialInput = useGetUserSelectionInputFromAutomationItem(
    automationItem || undefined,
  );
  const { userSelectionInput, setUserSelectionInput } =
    useUserSelectionInputState({
      input: initialInput,
      allowUserTypes: true,
    });
  const { userActionPermissions } = useActionPermissions();
  const canCreateAutomation = useMemo(() => {
    return Boolean(userActionPermissions?.updateAutomations);
  }, [userActionPermissions?.updateAutomations]);
  const [updateAccess, { loading: accessLoading }] =
    useUpdateAutomationMutation({
      variables: {
        id: automationItem?.automation.id || -1,
        input: { userSelection: userSelectionInput },
      },
    });
  if (!permissions) return null;
  return (
    <Modal
      title={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: deprecatedTones.black,
          }}
        >
          Publish module
        </div>
      }
      confirmButtonProps={{
        onClick: () => {
          updatePathSettingsAndPublishMutation();
          automationItem && updateAccess();
        },
        disabled: !canPublishMoreModules,
        copy: "Publish",
        loading: accessLoading || loadingUpdatePathSettingsAndPublishMutation,
        "data-testid": "publish-path-modal-submit-button",
      }}
    >
      {!canPublishMoreModules && (
        <AutoLayout paddingBottom={12} paddingHorizontal={20}>
          <UpgradePlanCTA description="You’ve reached the limit of published modules on the Starter plan. To continue either archive other modules or upgrade your plan." />
        </AutoLayout>
      )}
      {canPublishMoreModules && (
        <div className={css(styles.container)}>
          <div className={css(styles.publishSettingContainer)}>
            <div>
              <Text
                type="P1"
                fontWeight="SemiBold"
                color={deprecatedTones.black}
              >
                Due date
              </Text>
              <Spacer size={4} />
              <Text
                type="P3"
                fontWeight="Regular"
                color={deprecatedTones.gray11}
              >
                Require the trainee to finish the module by this time
              </Text>
            </div>
            <DueDatePicker
              dueDate={dueDate?.value.dueDate}
              setDueDate={(input) => setDueDate({ value: input })}
              dueDateDays={dueDate.value.dueDateDays}
            />
          </div>
          {canCreateAutomation && automationItem && (
            <PublishModalLibraryAccessPicker
              userSelectionInput={userSelectionInput}
              setUserSelectionInput={setUserSelectionInput}
            />
          )}
        </div>
      )}
    </Modal>
  );
};

export default PublishPathModal;

gql`
  mutation updatePathSettingsAndPublish(
    $pathId: Int!
    $pathSettingsInput: PathSettingsInput!
  ) {
    updatePathSettings(pathId: $pathId, input: $pathSettingsInput) {
      success
    }
    publishPathV2(pathId: $pathId) {
      success
    }
  }

  fragment PublishPathModal on Path {
    id
    libraryItem {
      ...AutomationLibraryItem
    }
  }

  query SavePathModal($pathId: Int!) {
    Path: AdminPath(id: $pathId) {
      ...PublishPathModal
    }
  }
`;

const styles = StyleSheet.create({
  container: {
    display: "flex",
    height: "100%",
    width: "100%",
    padding: "30px 20px",
    flexDirection: "column",
  },
  publishSettingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 24,
  },
  pathDescriptionContainer: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
});
