import {
  PathMembershipItemData,
  PathMembershipSectionItems,
  SectionBreakMembershipItemData,
} from "@src/components/libraryItemDetailPages/module/hooks/usePathMembershipItems";

export const getMembershipSections = (
  membershipItems: PathMembershipItemData[],
): PathMembershipSectionItems[] => {
  if (membershipItems.length === 0) return [];
  // First item must be a section break, otherwise there are no section breaks
  const firstItem = membershipItems[0];
  if (!(firstItem instanceof SectionBreakMembershipItemData)) {
    return [];
  }
  const sections: PathMembershipSectionItems[] = [];
  let newSection: PathMembershipSectionItems = {
    id: firstItem.id,
    items: [firstItem],
  };
  const membershipItemsWithoutFirstSectionBreak = membershipItems.slice(1);
  membershipItemsWithoutFirstSectionBreak.forEach((item) => {
    if (item instanceof SectionBreakMembershipItemData) {
      sections.push(newSection);
      newSection = {
        id: item.id,
        items: [item],
      };
    } else {
      newSection.items.push(item);
    }
  });
  // Add the last section
  sections.push(newSection);
  return sections;
};
