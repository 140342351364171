import { CourseContentLessonFragment } from "@src/components/libraryItemDetailPages/course/build/CourseBuildContent.generated";
import { DeprecatedToneName } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { PublishState } from "@src/types.generated";

export const PUBLISH_STATE_COPY: { [key in PublishState]: string } = {
  [PublishState.Draft]: "Draft",
  [PublishState.Published]: "Published",
  [PublishState.UnpublishedChanges]: "Unpublished changes",
};

export const PUBLISH_STATE_COLOR: {
  [key in PublishState]: DeprecatedToneName;
} = {
  [PublishState.Draft]: "yellow",
  [PublishState.Published]: "green",
  [PublishState.UnpublishedChanges]: "orange",
};

export function isLessonIncompleteAI(
  lesson: CourseContentLessonFragment,
): boolean {
  if (!lesson.aiLessonGeneration) {
    return false;
  }
  // If we've tried to generate message prompts and failed, we won't attempt to generate
  // questions, so lesson generation is complete
  if (
    lesson.aiLessonGeneration.messagePromptsAttempted &&
    !lesson.aiLessonGeneration.messagePromptsSuccess
  ) {
    return false;
  }
  // If we've attempted to generate question prompts--even if the attempt fails--this is the last
  // step, so generation is complete
  if (lesson.aiLessonGeneration.questionPromptsAttempted) {
    return false;
  }
  return true;
}
